import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import LeftpanelMember from "../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../components/layout/header/header";
import LoadingOverlay from "react-loading-overlay";
import "./dashboard-member.css";
import StarAccessModal from "../../components/star-access-modal";

import {
  Banner_1,
  Shopping,
  ReferFriend,
  Certificatebanner,
  TrainingVideos,
  ResourcesBanner,
  PrefferedPartners,
  AffiliateDashboard,
  tvi_star_image
} from "../../assets/images";
import axios from "../../axios/axios";
import { message } from "antd";
import apiConfig from "../../config/api.config";
import { setSuccessCode } from '../../actions/signUp.actions';
import { useSelector, useDispatch } from 'react-redux';

const DashboardPart = () => {
  const [user, setUser] = useState(
    localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
  );
  const [arriviaUserId, setArriviaUserId] = useState(
    user ? user.arriviaUserId : null
  );
  const [show, setShow] = useState(false);
  const [first, setFirst] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [last, setLast] = useState("");
  const [address, setAddress] = useState("");
  const [loadTravel, setLoadTravel] = useState("");
  const [subPackagePrice, setSubPackagePrice] = useState("");
  const [starAccessModalShow, setStarAccessModalShow] = useState(false);

  const successModal = useSelector(state => state.changeStep.successCode)
  const dispatch = useDispatch();
  const starModalCose = () => {
    setStarAccessModalShow(false)
  };
  const modalClose = () => {
    setLoadTravel(false);
    setShow(false);
  };
  const getBaseURL = () => {
    switch (process.env.ENV) {
      case "STAGING":
        return process.env.REACT_APP_API_URL_STAGING

      case "DEVELOPEMENT":
        return process.env.REACT_APP_API_URL_LOCAL

      default:
        return process.env.REACT_APP_API_URL_STAGING
    }
  }
  const handleFirst = (event) => {
    switch (event.target.name) {
      case "first":
        setFirst(event.target.value);
        break;
      case "last":
        setLast(event.target.value);
        break;
      case "first":
        setFirst(event.target.value);
        break;
      case "address":
        setAddress(event.target.value);
        break;
      case "city":
        setCity(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;

      default:
        setCountry(event.target.value);
        break;
    }
  };
  const modalShow = () => setShow(true);
  const arriviaRegister = async () => {
    let isRegistered = false;
    if (!first || !phone || !city || !country || !last || !address) {
      console.log(first);
      message.warning("Please fill up the form properly");
    } else {
      modalClose();
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      };
      message.warning("Registering to dashboard, please wait");
      let data = {
        firstName: first,
        lastName: last,
        phone,
        country,
        city,
        address,
      };
      await axios
        .post(`${apiConfig.api}user/arrivia_register`, data, config)
        .then((res) => {
          if (res.data.status) {
            setArriviaUserId(res.data.data);
            let userDetails = JSON.parse(localStorage.getItem("userDetails"));
            userDetails.arriviaUserId = res.data.data;
            console.log(userDetails, "us");
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            isRegistered = true;
            window.open(apiConfig.arriviaRedirectUrl + res.data.data, "_blank");
          } else {
            message.error("Something went wrong. Login failed!");
          }
        })
        .catch((err) => {
          console.log("err", err);
          message.error(
            err.response.data.message ??
            "Something went wrong. Please try again letter"
          );
        });

      if (isRegistered) {
        message.success("User is successfully registered.");
      } else {
      }
    }
  };
  useEffect(() => {
    console.log('Dashboard Page')
    if (!localStorage.getItem("AuthToken")) {
      var token = localStorage.getItem("AuthToken");
      // setToken(token)
      window.open("/login", "_self");
    }
    if (user) {
      axios
        .get(`user/get-user-id-by-affiliate-details/${user.id}`)
        .then((res) => {
          if (res.data.status) {
            setSubPackagePrice(res.data.data?.belongsToUser?.packagePrice);
            localStorage.setItem("subPrice", res.data.data?.belongsToUser?.packagePrice);
          }
        })
        .catch((err) => {
          console.log("err", err);

        });
    }


  }, []);

  useEffect(() => {
    console.log(successModal);
  }, [successModal]);

  const handleModalCloseSuccess = () => {
    dispatch(setSuccessCode(false))
  }


  const arriviaLogin = async () => {
    setLoadTravel(true);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("AuthToken")}` },
    };
    if (!arriviaUserId) {
      modalShow();
      return 0;
    }
    // else message.success("Redrecting you to TravelDeals dashboard, please wait");
    await axios
      .post(`${apiConfig.api}user/arrivia_login`, {}, config)
      .then((res) => {
        if (res.data.status) {
          setLoadTravel(false);
          message.success("Redirecting to dashboard. Please wait...");
          window.open(apiConfig.arriviaRedirectUrl + res.data.data, "_blank");
        } else {
          message.error("Something went wrong. Login failed!");
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoadTravel(false);
        message.error(
          err.response.data.message ??
          "Something went wrong. Please try again letter"
        );
      });
  };
  return (
    <div>
      <HeaderPart userDetails={user} />
      {/* <LoadingOverlay active={false} spinner text="Loading ..."> */}
      <div className="main_cont">
        <LeftpanelMember
          arriviaLogin={() => {
            arriviaLogin();
          }}
        />
        <div className="right_cont">
          <div className="right_banner_banner_cnt">
            <Row>
              <Col lg="6" md="6" sm="12" className="right_innrBanner">
                <div className="right_inner_inner_banner">
                  <a href="/travel-certificate-dashboard" className="banner_box" target="_blank">
                    <img src={Certificatebanner} className="img-fluid" alt="" />
                    <div className="banner_overlay">Certificates</div>
                  </a>
                </div>
              </Col>

              <Col lg="6" md="6" sm="12" className="right_innrBanner">
                <div className="right_inner_inner_banner">
                  <a
                    target="_blank"
                    href="/training-video-dashboard"
                    className="banner_box"
                  >
                    <img src={TrainingVideos} className="img-fluid" alt="" />
                    <div className="banner_overlay">Training Videos</div>
                  </a>
                </div>
              </Col>
              <Col lg="6" md="6" sm="12" className="right_innrBanner">
                <LoadingOverlay active={loadTravel} spinner text="Loading ...">
                  <div className="right_inner_inner_banner">
                    <a
                      href="#"
                      className="banner_box"
                      onClick={() => {
                        arriviaLogin();
                      }}
                    >
                      <img src={Banner_1} className="img-fluid" alt="" />
                      <div className="banner_overlay">
                        Travel Portal
                        <p className="banner_small_text">First time users fill out "Don't have an account? Sign Up"</p>
                      </div>
                    </a>
                  </div>
                </LoadingOverlay>
                {/* <div className="right_inner_inner_banner">
                    <a
                      href="#"
                      className="banner_box"
                      onClick={() => {
                        arriviaLogin();
                      }}
                    >
                      <img src={Banner_1} className="img-fluid" alt="" />
                      <div className="banner_overlay">Travel Deals</div>
                    </a>
                  </div> */}
              </Col>

              <Col lg="6" md="6" sm="12" className="right_innrBanner">
                <div className="right_inner_inner_banner">
                  <a href={getBaseURL() + "user/benifithub"}
                    // to={links.length ? links[0].link : "/coming-soon"}
                    target="_blank" className="banner_box">
                    <img src={Shopping} className="img-fluid" alt="" />
                    <div className="banner_overlay">
                      Shopping & Entertainment
                      <p className="banner_small_text">First time users fill out "Don't have an account? Sign Up"</p>
                    </div>
                  </a>
                </div>
              </Col>

              {(subPackagePrice && subPackagePrice == '4997' || subPackagePrice == '1997') ? (<>
                <Col lg="6" md="6" sm="12" className="right_innrBanner">
                  <div className="right_inner_inner_banner">
                    <a href="/tvi-star-dashboard" className="banner_box" target="_blank">
                      <img src={tvi_star_image} className="img-fluid" alt="" />
                      {/* <div className="banner_overlay">Resources</div> */}
                    </a>
                  </div>
                </Col>

              </>) : (<>
                <Col lg="6" md="6" sm="12" className="right_innrBanner">
                  <div className="right_inner_inner_banner">
                    <a href="javascript:void(0)" onClick={(e) => {
                      setStarAccessModalShow(true);
                    }} className="banner_box">
                      <img src={tvi_star_image} className="img-fluid" alt="" />
                      {/* <div className="banner_overlay">Resources</div> */}
                    </a>
                  </div>
                </Col>

              </>)}
              <Col lg="6" md="6" sm="12" className="right_innrBanner">
                <div className="right_inner_inner_banner">
                  <a href="/resources-dashboard" className="banner_box" target="_blank">
                    <img src={ResourcesBanner} className="img-fluid" alt="" />
                    <div className="banner_overlay">Resources</div>
                  </a>
                </div>
              </Col>

              <Col lg="6" md="6" sm="12" className="right_innrBanner">
                <div className="right_inner_inner_banner">
                  <a href="/preferred-partner" target="_blank" className="banner_box">
                    <img src={PrefferedPartners} className="img-fluid" alt="" />
                    <div className="banner_overlay">Preferred Partners</div>
                  </a>
                </div>
              </Col>
              {user && user.affiliate == "" ? <Col lg="6" md="6" sm="12" className="right_innrBanner">
                <div className="right_inner_inner_banner">
                  <a
                    href="/affiliate-dashboard"
                    className="banner_box"
                  >
                    <img
                      src={AffiliateDashboard}
                      className="img-fluid"
                      alt=""
                    />
                    <div className="banner_overlay1">Affiliate Dashboard</div>
                  </a>
                </div>
              </Col> : ""}



              {/* <Col lg="6" md="6" sm="12" className="right_innrBanner">
                <div className="right_inner_inner_banner">
                  <a
                    href="#"
                    onClick={() => {
                      window.open(
                        "https://www.tripvaletincentives.com/affiliate"
                      );
                    }}
                    className="banner_box"
                  >
                    <img src={ReferFriend} className="img-fluid" alt="" />
                    <div className="banner_overlay">Refer a friend</div>
                  </a>
                </div>
              </Col> */}
            </Row>
          </div>
        </div>
      </div>
      {/* </LoadingOverlay> */}
      {(starAccessModalShow) ? (<>

        <StarAccessModal show={starAccessModalShow} starModalCose={starModalCose} />
      </>) : (<></>)}
      <Modal show={show}>
        <Modal.Body>
          <div className="popuptext">
            <Button
              variant="secondary"
              className="close_button"
              onClick={modalClose}
            ></Button>
            <h3>Title</h3>
            <div className="modal_form_group">
              <Form>
                <Row>
                  <Col lg="6" md="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        name="first"
                        className="modal_t_field"
                        value={first}
                        onChange={handleFirst}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        className="modal_t_field "
                        name="last"
                        value={last}
                        onChange={handleFirst}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone Number"
                    className="modal_t_field"
                    name="phone"
                    value={phone}
                    onChange={handleFirst}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    className="modal_t_area"
                    placeholder="Enter Address"
                    name="address"
                    value={address}
                    onChange={handleFirst}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    className="modal_t_field"
                    name="city"
                    value={city}
                    onChange={handleFirst}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    className="modal_t_field"
                    name="country"
                    value={country}
                    onChange={handleFirst}
                  >
                    <option value="00">Select country</option>
                    <option value="IN">India</option>
                    <option value="UM">United States</option>
                  </Form.Control>
                </Form.Group>
                <div className="modal_submit">
                  <Button
                    variant="primary"
                    type="button"
                    className="sub_but"
                    onClick={() => {
                      arriviaRegister();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={successModal} onHide={handleModalCloseSuccess}>
        <Modal.Body>
          <div className="modal_close">
            <h2 className="text-center">Congratulations!</h2>
            <h3>You have successfully updated your new membership plan.</h3>
            <Button
              variant="secondary"
              onClick={handleModalCloseSuccess}
              className="close_buuut"
            ></Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DashboardPart;
