import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const StarAccessModal = (props) => {
    const history = useHistory();
    return (
        <>
            <Modal show={props.show} onHide={props.starModalCose}>
                <Modal.Body>
                    <div className="modal_close">
                        <h3>Please upgrade to Star packages to access these benefits.</h3>
                        <Button
                            variant="secondary"
                            onClick={props.starModalCose}
                            className="close_buuut"
                        ></Button>
                    </div>
                </Modal.Body>
                <Modal.Footer className="footer_but">
                    <Button variant="secondary" onClick={() => {
                        history.push(`/my-account?star=1`);
                    }} className="yesbut">
                        Yes
                    </Button>
                    <Button variant="primary" onClick={props.starModalCose} className="nobut">
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StarAccessModal;